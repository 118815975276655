@import "styles/mixins";
@import "styles/variables";

.logo {
  width: 90px;
  height: 40px;
  @include media($breakpoint-sm) {
    width: 105px;
    height: 47px;
  }
}

.nav {
  position: sticky;
  top: 0;
  height: 60px;
  z-index: 10;
  padding: 0 20px;
  background-color: var(--light-yellow);
  @include media($breakpoint-sm) {
    height: 90px;
  }
  &__container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }
  &__cart_container {
    display: flex;
    margin: 0;
    @include media($breakpoint-sm) {
      width: 100%;
      max-width: 320px;
      align-items: center;
    }
  }
  &__cart_counter {
    display: none;
    @include media($breakpoint-sm) {
      display: inline;
      margin-left: 8px;
    }
  }
}

.drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -380px;
  width: 100%;
  overflow-y: auto;
  background: white;
  z-index: 100;
  padding: 0 20px;
  box-shadow: var(--shadow-cart);
  transition: all 500ms ease-in;
  &.open {
    right: 0;
    animation: show 500ms ease-out;
  }
  @include media($breakpoint-sm) {
    max-width: 380px;
  }
  @keyframes show {
    from {
      right: -380px;
    }
    to {
      right: 0%;
    }
  }
}

.modal {
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.75);
  }
  &__element {
    width: 100%;
    background: white;
    border: none;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 40px;
    box-shadow: var(--shadow-cart);
    height: auto;
    min-height: 100vh;
    top: 2rem;
    opacity: 0;
    transition: all 300ms ease-in-out;
    @include media($breakpoint-sm) {
      top: 90px;
      padding: 80px;
      max-width: 760px;
      min-height: unset;
      border-radius: 16px;
      box-shadow: var(--shadow-lg);
    }
    &.open {
      top: 0;
      opacity: 1;
      animation: show-compact 300ms ease-in-out;
      @include media($breakpoint-sm) {
        top: 60px;
        animation: show-regular 300ms ease-in-out;
      }
    }
    @keyframes show-compact {
      from {
        opacity: 0;
        top: 2rem;
      }
      to {
        opacity: 1;
        top: 0;
      }
    }
    @keyframes show-regular {
      from {
        opacity: 0;
        top: 90px;
      }
      to {
        opacity: 1;
        top: 60px;
      }
    }
  }
}

.loading {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
  }
  &__spinner {
    animation: spinner 1250ms infinite linear;
    border: 8px solid var(--yellow);
    border-right-color: transparent;
    border-radius: 50px;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-indent: -9999px;
    width: 48px;
    height: 48px;
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.footer {
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background: var(--lighter-grey);
    color: var(--light-grey);
    padding-left: 40px;
    @include media($breakpoint-sm) {
      height: 80px;
      padding-left: 80px;
    }
  }
  &__detail {
    margin-bottom: 80px;
    @include media($breakpoint-md) {
      margin: 0;
    }
  }
}
