@import "styles/mixins";
@import "styles/variables";

.drawer {
  &__header_container {
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 0 20px;
    @include media($breakpoint-sm) {
      height: 90px;
    }
  }
  &__cart_container {
    display: flex;
    align-items: center;
  }
  &__counter {
    margin-left: 8px;
  }
}

.list {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    align-items: flex-start;
    padding-bottom: 90px;
    min-height: calc(100vh - 80px);
    @include media($breakpoint-sm) {
      min-height: calc(100vh - 121px);
      margin-top: 31px;
    }
  }
  &__subtotal_container {
    display: flex;
    padding: 20px;
    padding-bottom: 40px;
    flex-direction: column;
    align-items: flex-start;
  }
  &__subtotal {
    color: var(--light-grey);
  }
  &__price_container {
    display: flex;
    margin: 0;
  }
  &__price {
    white-space: nowrap;
  }
  &__currency {
    margin-left: 3px;
    margin-top: 12px;
    white-space: nowrap;
  }
  &__empty_text {
    display: flex;
    width: 100%;
    height: 100px;
    margin-left: 0;
    justify-content: center;
  }
  &__checkout_button_container {
    display: flex;
    width: 100%;
    margin-left: 0;
    margin-top: auto;
    align-self: flex-end;
    padding: 0 20px;
  }
}

.item {
  &__box {
    display: flex;
    width: 100%;
    height: 150px;
    border-radius: 6px;
    background: var(--lighter-grey);
  }
  &__image_container {
    display: flex;
    position: relative;
    width: 35%;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &__image {
    position: absolute;
    height: 100%;
    left: -50%;
  }
  &__content_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 65%;
    padding: 20px;
  }
  &__title_container {
    display: flex;
    gap: 6px;
  }
  &__title {
    color: var(--blue);
    @include two-line-overflow;
  }
  &__delete_button {
    align-self: flex-start;
    margin-left: auto;
  }
  &__price_container {
    display: flex;
    margin-top: auto;
    align-items: center;
  }
  &__price {
    margin-left: 10px;
    white-space: nowrap;
  }
  &__currency {
    margin-left: 3px;
    margin-top: 5px;
    white-space: nowrap;
  }
}
