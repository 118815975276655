@import "styles/mixins";
@import "styles/variables";

.filter_button {
  width: max-content;
  padding-right: 10px;
  @include media($breakpoint-md) {
    display: none;
  }
  @include media($breakpoint-lg) {
    display: block;
  }
  @include media($breakpoint-xl) {
    display: none;
  }
  &__container {
    display: flex;
    position: relative;
    align-items: center;
    gap: 3px;
    color: var(--blue);
  }
  &__list_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20px;
    z-index: 100;
    background: white;
    border-radius: 6px;
    border: 1px solid var(--lighter-grey);
    width: max-content;
    text-align: left;
    color: var(--darker-grey);
    & > .selected {
      color: var(--blue);
    }
  }
  &__list_item_all {
    padding: 10px 20px;
    padding-left: 50px;
  }
  &__list_item_category {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;
  }
}

.filter_sidebar {
  display: none;
  @include media($breakpoint-md) {
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 85px;
    color: var(--light-grey);
  }
  @include media($breakpoint-lg) {
    display: none;
  }
  @include media($breakpoint-xl) {
    display: flex;
  }
  &__label {
    white-space: nowrap;
    margin-left: 38px;
    margin-right: 20px;
  }
  &__list_container {
    display: flex;
    flex-direction: column;
    width: max-content;
    color: var(--darker-grey);
    margin: 0;
    & > .selected {
      color: var(--blue);
      padding-bottom: 3px;
      margin-bottom: 7px;
      position: relative;
      &::after {
        content: "";
        display: inline;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        margin-right: auto;
        background: var(--blue);
      }
    }
  }
  &__list_item_all {
    cursor: pointer;
    padding: 10px 0;
    margin-left: 33px;
    width: max-content;
    &.selected::after {
      width: 100%;
    }
  }
  &__list_item_category {
    cursor: pointer;
    padding: 10px 0;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 5px;
    &.selected::after {
      width: calc(100% - 30px);
      margin-left: 30px;
    }
  }
}

.list {
  &__box {
    display: flex;
    width: 100%;
    flex-direction: column;
    @include media($breakpoint-md) {
      margin-top: 35px;
      width: max-content;
    }
    @include media($breakpoint-lg) {
      width: 100%;
    }
    @include media($breakpoint-xl) {
      width: max-content;
    }
  }
  &__title_container {
    padding: 14px 22px 18px;
    @include media($breakpoint-md) {
      padding: 0 22px;
    }
    @include media($breakpoint-xl) {
      padding: 0;
    }
  }
  &__displayed {
    color: var(--light-grey);
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
    @include media($breakpoint-sm) {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 40px;
      margin: 40px 20px;
      justify-content: space-between;
    }
    @include media($breakpoint-md) {
      justify-items: flex-start;
    }
    @include media($breakpoint-lg) {
      justify-content: space-between;
      grid-template-columns: repeat(3, minmax(auto, 1fr));
    }
    @include media($breakpoint-2xl) {
      margin: 40px 0;
      grid-template-columns: repeat(4, minmax(auto, 1fr));
    }
  }
  &__load_more_button {
    margin-bottom: 35px;
    @include media($breakpoint-sm) {
      margin-bottom: 60px;
    }
    @include media($breakpoint-lg) {
      margin-left: auto;
      margin-right: 228px !important;
      & > h5 {
        font-size: 18px;
        line-height: 22.5px;
      }
    }
  }
}

.item {
  &__box {
    display: flex;
    width: 100%;
    height: 150px;
    background: white;
    border-radius: 6px;
    border: 1px solid var(--lighter-grey);
    @include media($breakpoint-sm) {
      flex-direction: column;
      width: 320px;
      height: 450px;
      border-radius: 8px;
      &:hover {
        border: none;
        box-shadow: var(--shadow-hover);
      }
    }
  }
  &__graphics_container {
    position: relative;
    height: 150px;
    width: 35%;
    @include media($breakpoint-sm) {
      width: 320px;
      height: 180px;
      min-height: 180px;
    }
  }
  &__image_container {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    @include media($breakpoint-sm) {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  &__image {
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  &__category_container {
    position: absolute;
    height: 32px;
    width: 32px;
    top: 13px;
    right: -16px;
    @include media($breakpoint-sm) {
      top: unset;
      right: 20px;
      bottom: -20px;
      height: 40px;
      width: 40px;
    }
  }
  &__category_icon {
    height: 24px;
    width: 24px;
    @include media($breakpoint-sm) {
      height: 32px;
      width: 32px;
    }
  }
  &__details_container {
    display: flex;
    width: 65%;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    padding-left: 25px;
    @include media($breakpoint-sm) {
      padding: 20px 40px;
      width: 100%;
      height: 100%;
    }
  }
  &__datetime_container {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  &__datetime_icon {
    margin-right: 3px;
  }
  &__date {
    margin-right: 10px;
  }
  &__title {
    color: var(--blue);
    margin-top: 5px;
    @include two-line-overflow;
    @include media($breakpoint-sm) {
      margin-top: 10px;
    }
  }
  &__price_container {
    display: flex;
    width: 100%;
    @include media($breakpoint-sm) {
      margin-top: auto;
    }
  }
  &__price {
    margin-top: 10px;
    white-space: nowrap;
  }
  &__currency {
    margin-left: 5px;
    white-space: nowrap;
    margin-top: 16px;
    @include media($breakpoint-sm) {
      margin-top: 24px;
    }
  }
  &__button_icon {
    height: 40px;
    width: 40px;
    margin-left: auto;
    @include media($breakpoint-sm) {
      display: none;
    }
  }
  &__button_text {
    height: 50px;
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
    display: none;
    @include media($breakpoint-sm) {
      display: block;
      margin-bottom: 17px;
    }
  }
}
